import React, { useState, useEffect } from "react"
import { View, Text, TouchableOpacity, Alert, SafeAreaView, ScrollView, Image, StatusBar, ActivityIndicator, Linking } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, addDoc, Timestamp  } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import styles from "./style";
import  * as DocumentPicker from 'expo-document-picker';



export default function CasoExtra({ navigation }) {

    const [image, setImage] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");

    const [referenciadoc, setReferenciadoc] = useState(null);
    const [referencia, setReferencia] = useState(null);
    const [referenciaPDF, setReferenciaPDF] = useState(null);

    const [uploading, setUploading] = useState(false);
    const [uploading2, setUploading2] = useState(false);
    const [uploading3, setUploading3] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail] = useState("");
    const [consulta, setConsulta] = useState(null);
    const [grupo, setGrupo] = useState("");
    const [count, setCount] = useState(0)

    const [dataAtual, setDataAtual] = useState("")
   

    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }



    const UploadResumos = async () => {

        try {
            const db = getFirestore();
            
            const docRef = await addDoc(collection(db, "Resumos"), {
                to: [email],
                message: {
                    subject: 'Soporte Rayane Pinto!',
                    html: '<p>Hola Doc. ' + (consulta.nome) + ', ¡saludos! </p><p>Recibimos su caso clínico. La documentación enviada será analizada por el equipo de soporte y pronto nos pondremos en contacto para dar la devolutiva planificación del caso clínico.</p><p>Equipo de Soporte Profª Rayane Pinto<br/>Odontología Sin Fronteras Cursos e Treinamentos LTDA</p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                },

                email: email,
                ifoUsuario: consulta,
                Anamnesis: referencia,
                DocPaciente: referenciadoc,
                CefalometriaPaciente: referenciaPDF,
                Envio: dataAtual,
                data: Timestamp.fromDate(new Date()),
                numeroCaso: "Caso Extra",
                status: "Esperando validación de soporte", 
                validata: "",
                initData: "",
                Prof: "",
                Planej: "",
                face: "",
                tipo: "CASO",
                Arquivo: "",
                Motivo: ""
            });

        } catch (e) {
            console.error("Error adding document: ", e);
        }

        alert("¡Formulario enviado con éxito!", "Se envió un mensaje de confirmación a tu correo electrónico")

        navigation.navigate("Home")

    }


     useEffect(() => {
        (async () => {
                if (user !== null) {
                    user.providerData.forEach((profile) => {
                        setEmail(profile.email)
                    });
                }
        })();
    }, []);


    useEffect(() => {
        getInfo();
        Tick();
    },[referencia])


    const getInfo = async () => {

        for (var i = 0; i <= 2; i++) {
            const db = getFirestore();
            const citiesRef = collection(db, "Usuarios");
            const q = query(citiesRef, where("email", "==", email));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
            
            setGrupo(doc.data().grupo);

            const obj = {
                email: doc.data().email,
                grupo: doc.data().grupo === "Grupo5" ? "Grupo5" : "Grupo4",
                nome: doc.data().nome,
                diplo: "Formula",
            }

            setConsulta(obj);
          });
        }
    }

    

  
    
    useEffect(() => {
        setUploading(false);
    },[referenciadoc])

    useEffect(() => {
        setUploading2(false);
    },[referencia])

    useEffect(() => {
        setUploading3(false);
    },[referenciaPDF])

    const pickImage = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading2(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url +'.pdf');
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    const pickImage2 = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage2(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url + ".pptx");
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferenciadoc(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }
    

    const pickImage3 = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage3(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading3(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url +'.pdf');
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferenciaPDF(UrlDownload)
        setCount(3)
        blob.close();
    
        } catch(error) {

        }
    }

    return (

        <LinearGradient style={styles.container}
        colors={['#49009C', '#0D0D0D']}
        >
    <SafeAreaView>
            <View style={styles.header}>
                    <View>
                        <Text style={styles.Title}>Entrega Caso Clínico Extra</Text>
                </View>

                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff"
                            style={{justifyContent: 'center', alignContent: 'center' }}
                            />
                    </TouchableOpacity>
            </View>

            <ScrollView style={{flex: 1}}>

                <Text style={styles.descricao3}>
                    Adjunta el Guía de Anamnesis
                </Text>
                
                {uploading2 === false && image === "" ?
                <TouchableOpacity style={styles.buttomFoto} onPress={pickImage}>
                    <MaterialCommunityIcons
                        name="file-export"
                        size={24}
                        color="#ffffff" />

                    <Text style={styles.textBotao}> Sellar un archivo
                    </Text>
                </TouchableOpacity>
                : 
                <ActivityIndicator style={{ marginTop: 25 }} animating={uploading2} size={60} color="#FFF" />
                }

                {uploading2 === false && image !== "" && referencia != null &&
                    <View style={styles.divUpload}>
                        <Text style={styles.nameImg}>📄 {image}</Text>
                    
                    <TouchableOpacity onPress={pickImage} style={styles.botaoEliminar}>
                        <Text style={styles.textEliminar}>Eliminar</Text>
                    </TouchableOpacity>

                    </View>
                }

                <Text style={styles.descricao4}>
                Adjunta la documentación completa del paciente (PowerPoint)
                </Text>

                {uploading === false && image2 === "" ?
                <TouchableOpacity style={styles.buttomFoto} onPress={pickImage2}>
                    <MaterialCommunityIcons
                        name="file-export"
                        size={24}
                        color="#ffffff" />

                    <Text style={styles.textBotao}> Sellar un archivo
                    </Text>
                </TouchableOpacity>
                : 
                <ActivityIndicator style={{ marginTop: 25 }} animating={uploading} size={60} color="#FFF" />
                }

                {uploading === false && image2 !== "" && referenciadoc != null &&
                <View style={styles.divUpload}>
                    <Text style={styles.nameImg}>📄 {image2}</Text>
                
                    <TouchableOpacity onPress={pickImage2} style={styles.botaoEliminar}>
                        <Text style={styles.textEliminar}>Eliminar</Text>
                    </TouchableOpacity>

                </View>
                }


                <Text style={styles.descricao4}>
                Adjunta la cefalometría del paciente (PDF - WebCeph)
                </Text>

                {uploading3 === false && image3 === "" ?
                <TouchableOpacity style={styles.buttomFoto} onPress={pickImage3}>
                    <MaterialCommunityIcons
                        name="file-export"
                        size={24}
                        color="#ffffff" />

                    <Text style={styles.textBotao}> Sellar un archivo
                    </Text>
                </TouchableOpacity>
                : 
                <ActivityIndicator style={{ marginTop: 25 }} animating={uploading3} size={60} color="#FFF" />
                }

                {uploading3 === false && image3 !== "" && referenciaPDF != null &&
                <View style={styles.divUpload}>
                    <Text style={styles.nameImg}>📄 {image3}</Text>
                
                    <TouchableOpacity onPress={pickImage3} style={styles.botaoEliminar}>
                        <Text style={styles.textEliminar}>Eliminar</Text>
                    </TouchableOpacity>

                </View>
                }


                <Text style={styles.descricao}>
                    Cualquier duda, pueden enviar un mensaje: 
                    <TouchableOpacity onPress={() => {
                        Linking.openURL("https://api.whatsapp.com/send?phone=5514996207009&text=Hola,%20estoy%20en%20el%20Portal%20OSF%20-%20%20Env%C3%ADo%20de%20Casos%20Cl%C3%ADnicos%20y%20tengo%20una%20duda")}}>
                        <Text style={styles.Link}> +55 14 99620-7009</Text>
                    </TouchableOpacity>
                </Text>


                {count === 0 &&

                    <TouchableOpacity style={styles.buttomEnviar2}>

                        <Text style={styles.textbotaoEnviar}>
                            Enviar
                        </Text>

                    </TouchableOpacity>

                }

                {referenciadoc != null && referencia != null && referenciaPDF != null &&

                    <TouchableOpacity style={styles.buttomEnviar} onPress={UploadResumos}>

                        <Text style={styles.textbotaoEnviar}>
                            Enviar
                        </Text>

                    </TouchableOpacity>

                }



            </ScrollView>
</SafeAreaView>
        </LinearGradient>

    );


} 