import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Login from './src/Pages/Login';
import Home  from './src/Pages/Home';
import HomeDiplo from './src/Pages/HomeDiplo';
import HomeCasoClinico from './src/Pages/HomeCasoClinico';
import HomeCasoExtra from './src/Pages/HomeCasoExtra';
import HomeDAO from './src/Pages/HomeDAO';
import HomeIntercept from './src/Pages/HomeIntercept';

import Caso1 from './src/Pages/Caso1'
import Caso2 from './src/Pages/Caso2';
import Caso3 from './src/Pages/Caso3';
import CasoExtra from './src/Pages/CasoExtra';

import ConsultaCaso1 from './src/Pages/ConsultaCaso1';
import ConsultaCaso2 from './src/Pages/ConsultaCaso2';
import ConsultaCaso3 from './src/Pages/ConsultaCaso3';

import Trocadesenha from './src/Pages/Trocadesenha';
import Trocadesenha2 from './src/Pages/Trocadesenha2';

import Caso1I from './src/Pages/Intercept/Caso1';
import Caso2I from './src/Pages/Intercept/Caso2';
import Caso3I from './src/Pages/Intercept/Caso3';

import ConsultaCaso1I from './src/Pages/Intercept/ConsultaCaso1';
import ConsultaCaso2I from './src/Pages/Intercept/ConsultaCaso2';
import ConsultaCaso3I from './src/Pages/Intercept/ConsultaCaso3';

import Caso1DAO from './src/Pages/DAO/Caso1';
import Caso2DAO from './src/Pages/DAO/Caso2';


import ConsultaCaso1DAO from './src/Pages/DAO/ConsultaCaso1';
import ConsultaCaso2DAO from './src/Pages/DAO/ConsultaCaso2';



import CASO1COE from './src/Pages/COE/CASO';
import ConsultaCaso from './src/Pages/COE/ConsultaCaso';


const Stack = createStackNavigator()

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>

      <Stack.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
            headerStyle: {
              backgroundColor: '',
              shadowColor: 'transparent',
            },
          }}
        />

        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />
        
        <Stack.Screen
        name="HomeDiplo"
        component={HomeDiplo}
        options={{
          headerShown: false,
          headerLeft: null,
          headerTitleAlign: "center",
          headerStyle: {
            backgroundColor: '#054A59',
            shadowColor: 'transparent',
          },
        }}
      />

        <Stack.Screen
          name="HomeCasoClinico"
          component={HomeCasoClinico}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />

        <Stack.Screen
          name="HomeCasoExtra"
          component={HomeCasoExtra}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />

        <Stack.Screen
          name="HomeDAO"
          component={HomeDAO}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />

        <Stack.Screen
          name="Caso1"
          component={Caso1}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />
        <Stack.Screen
          name="Caso2"
          component={Caso2}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />

        <Stack.Screen
          name="Caso3"
          component={Caso3}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: '#054A59',
              shadowColor: 'transparent',
            },
          }}
        />
        
        <Stack.Screen
        name="ConsultaCaso1"
        component={ConsultaCaso1}
        options={{
          headerShown: false,
          headerLeft: null,
          headerTitleAlign: "center",
          headerStyle: {
            backgroundColor: '#054A59',
            shadowColor: 'transparent',
          },
        }}
      />
      
      <Stack.Screen
      name="ConsultaCaso2"
      component={ConsultaCaso2}
      options={{
        headerShown: false,
        headerLeft: null,
        headerTitleAlign: "center",
        headerStyle: {
          backgroundColor: '#054A59',
          shadowColor: 'transparent',
        },
      }}
    />
    
    <Stack.Screen
    name="ConsultaCaso3"
    component={ConsultaCaso3}
    options={{
      headerShown: false,
      headerLeft: null,
      headerTitleAlign: "center",
      headerStyle: {
        backgroundColor: '#054A59',
        shadowColor: 'transparent',
      },
    }}
  />
  
  <Stack.Screen
  name="CasoExtra"
  component={CasoExtra}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Trocadesenha"
  component={Trocadesenha}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Trocadesenha2"
  component={Trocadesenha2}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Caso1DAO"
  component={Caso1DAO}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Caso2DAO"
  component={Caso2DAO}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="ConsultaCaso1DAO"
  component={ConsultaCaso1DAO}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="ConsultaCaso2DAO"
  component={ConsultaCaso2DAO}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="ConsultaCaso"
  component={ConsultaCaso}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="CASO1COE"
  component={CASO1COE}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="HomeIntercept"
  component={HomeIntercept}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Caso1I"
  component={Caso1I}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Caso2I"
  component={Caso2I}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="Caso3I"
  component={Caso3I}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="ConsultaCaso1I"
  component={ConsultaCaso1I}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="ConsultaCaso2I"
  component={ConsultaCaso2I}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>

<Stack.Screen
  name="ConsultaCaso3I"
  component={ConsultaCaso3I}
  options={{
    headerShown: false,
    headerLeft: null,
    headerTitleAlign: "center",
    headerStyle: {
      backgroundColor: '#054A59',
      shadowColor: 'transparent',
    },
  }}
/>


      </Stack.Navigator>
    </NavigationContainer>
  );
}