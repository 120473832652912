import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    Title: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 20,
    },

    container: {
        flex: 1,
        zoom: 0.55,
    },

    menu: {
        alingnItens: 'center',
        height: 975,
        width: 300,
        borderTopRightRadius: 70,
        borderBottomRightRadius: 70,
        borderColor: '#000',
        borderStyle: 'solid',
        borderWidth: 2,
        borderLeftColor: '#000'
    },

    header: {
        height: 115,
        backgroundColor: 'tranparent',
        marginTop: 10
    },

    titleMenu: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
        marginBottom: 25,
        marginLeft: 25,
    },

    buttomMenu: {
        alignItems: 'left',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 10,
        marginLeft: 40,
    },

    MenuModulos: {
        fontSize: 25,
        textAlign: 'center',
        color: '#000',
        marginTop: 20,
        fontWeight: 'bold',
    },

    MenuDescription: {
        fontSize: 20,
        textAlign: 'center',
        color: '#fff',
    },

    divImg: {
        alingnItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },

    img: {
        width: 700,
        height: 700,
    },

    Caso: {
        backgroundColor: "#FFF", 
        borderRadius: 20, 
        borderColor: '#000',
        borderStyle: 'solid',
        borderWidth: 2,
        width: 325, 
        height: 80,
        margin: 30, 
    },

    div: {
        alignSelf: "center", 
        alignItems: "center", 
        alignContent: "center", 
        justifyContent: "center"
    },

    textInicial: {
        color: "#FFF", 
        fontSize: 45, 
        marginTop: 80,  
        marginLeft: 40, 
        marginRight: 40
    },

    text: {
        color: "#FFF", 
        fontSize: 35, 
        marginLeft: 40, 
        marginRight: 40, 
        textAlign: "justify"
    }, 

    view: {
        justifyContent: 'center', 
        alignItems: 'center', 
        alignContent: 'center',  
        marginTop: 60,
    },

    textCompra: {
        color: "#FFF", 
        fontSize: 45, 
        marginLeft: 40, 
        marginRight: 40, 
        textAlign: "center"
    },

    textAlerta: {
        color: "#F5E30A", 
        fontSize: 35, 
        marginLeft: 40, 
        marginRight: 40, 
        textAlign: "justify"
    },

    viewCaso: {
        justifyContent: 'center', 
        alignItems: 'center', 
        alignContent: 'center',  
        marginTop: 50,
    },

    textCaso:{
        color: "#FFF", 
        fontSize: 35,
        marginLeft: 40, 
        marginRight: 40, 
        textAlign: "center"
    }

});

export default styles