import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, SafeAreaView, ScrollView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


export default function Home({ navigation }) {

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail]= useState("");
    const [grupo, setGrupo] = useState("")
    const [diplo, setDiplo] =  useState("")
    const [nome, setNome] = useState("")
    const [cont, setCont] = useState(0)
    
    useEffect(() => {
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail(profile.email)
            });
        } 

        for (var i = 0; i <= 2; i++) {
            setCont(cont + i)
        }
    },[]);

    const getNome = async () => {
        for (var i = 0; i <= 2; i++) {
            const db = getFirestore();
            
            const citiesRef = collection(db, "Usuarios");
            const q = query(citiesRef, where("email", "==", email));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setNome(doc.data().nome);
                setGrupo(doc.data().grupo)
                setDiplo(doc.data().diplo)
            });
        }
    }

    console.log(diplo)

    useEffect(() =>{
        getNome();
    },[cont])

    

    return (

        <LinearGradient style={styles.container}
        colors={['#49009C', '#0D0D0D']}
        >
        <ScrollView style={{flex: 1}}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Home</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate("Login")} >
                    <MaterialCommunityIcons
                        name="location-exit"
                        size={55}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                        />
                </TouchableOpacity>
                
            </View>

                <View>
                   
                    <View style={styles.divImg}>
                        <Animatable.Image 
                        animation= "fadeInDown"
                        style={styles.img} source={{
                            uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                        }} />
                    </View>
                    <Text style={styles.textNome}>Hola {nome}, que bueno verte aquí </Text>

                    <View style={styles.viewBotao}>
                        <TouchableOpacity style={styles.botaoCaso}  onPress={() => {
                                navigation.navigate("HomeDiplo")
                            }}>
                            <Text style={styles.MenuModulos}>🦷  Envio de Casos Clínicos</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </ScrollView>
        </LinearGradient>
    )
}