import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Linking, SafeAreaView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs, getFirestore} from "firebase/firestore";


export default function HomeCasoExtra({ navigation }) {

    const [open, setOpen] = useState(false)
    
    return (

        <LinearGradient style={styles.container}
        colors={['#49009C', '#0D0D0D']}>
        <SafeAreaView>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Casos Clínicos</Text>
            </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={55}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                        />
                </TouchableOpacity>
                
            </View>

            <ScrollView style={{flex: 1}}>

                <View style={styles.div}>
                    <Text style={styles.textInicial}>¡Hola mi querida, mi querido doc!</Text>

                    {open === false &&
                    
                        <Text style={styles.text}>
                            {'\n'}Yo sé cómo las planificaciones de casos clínicos son importantes y direccionan casos complexos. 
                            Esta parte de nuestra página esta dedicada... <TouchableOpacity onPress={() => setOpen(true)}><Text style={{color: "#F5E30A"}}> Seguir leyendo</Text></TouchableOpacity> 
                        </Text>
                    }


                    {open !== false &&
                        <>
                            <Text style={styles.text}>
                                {'\n'}Yo sé cómo las planificaciones de casos clínicos son importantes y direccionan casos complejos. 
                                Esta parte de nuestra página esta dedicada a usted que hizo el Diplomado Intercept o Fórmula Correctiva 
                                y ya envió sus 3 casos clínicos y para usted que perdió el plazo para enviar sus casos, pero desea la planificación de nuestro equipo de profesores. 
                            </Text>

                            <Text style={styles.text}>
                                {'\n'}Recuerdo que todos los profesores del Equipo son PhD en Ortodoncia y las planificaciones son divididas en Diagnóstico, 
                                Plan de tratamiento y secuencia del tratamiento.
                            </Text>

                            <Text style={styles.text}>
                                {'\n'}Para tener la planificación de su caso clínico extra: {'\n\n'}
                                1. Cancele el valor para la planificación de 1 caso clínico;{'\n'}
                                2. Agregue la documentación completa de su paciente 
                                (power point con fotos faciales, fotos de los modelos, radiografías y las sugerencias que has pensado para el caso; guía de ananmnesis y cefalometría digital){'\n'}
                                3. Espere la comunicación con nuestro equipo por correo
                            </Text>
                        </>
                    }
                        <View>
                            <View style={styles.view}>

                                <Text style={styles.textCompra}>
                                    Comprar caso clínico
                                </Text>

                    
                                <Text style={styles.textAlerta}>
                                Atención: En primer lugar haga clic en el botón de abajo para encender o enlace de compras de los casos clínicos extra . Al acceder a la página haga clic en "Ver horarios" Seleccione cualquier día y luego seleccione uno de los horarios disponibles. 
                                A continuación, aparecerá "Ir al pago". En caso de error, vuelva y elija otro horario.{"\n\n"}
                                </Text>

                                    <TouchableOpacity style={styles.Caso} onPress={() => {Linking.openURL("https://hotmart.com/es/marketplace/productos/analisis-de-caso-clinico-extra/E63079275V")}}>
                                        <Text style={styles.MenuModulos}>🦷 Haga clic para comprar</Text>
                                    </TouchableOpacity>
                                </View>

                                <View style={styles.viewCaso}>

                                    <Text style={styles.textCaso}>
                                        Enviar caso clínico
                                    </Text>

                                    <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("CasoExtra")}>
                                        <Text style={styles.MenuModulos}>🦷 Haga clic para enviar</Text>
                                    </TouchableOpacity>
                                </View>

                        </View>
                    
                </View>

            </ScrollView>
        </SafeAreaView>
        </LinearGradient>
    )
}