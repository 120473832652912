import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, SafeAreaView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs, getFirestore} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';


export default function HomeCasoClinico({ navigation }) {

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail]= useState("");

    const [retorno, setRetorno] = useState([])

    useFocusEffect(
        React.useCallback(() => {
            if (user !== null) {
                user.providerData.forEach((profile) => {
                    setEmail(profile.email)
                });
            } 
        }, [])
      );

    async function Caso1() {
                  
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("numeroCaso", "==", '1'), where("email", "==", email), where("ifoUsuario.diplo", "not-in", ['DAO', 'Intercept', 'Formula']))
        let array = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                status: doc.data().status,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente
            };
            array.push(Obj)
        });

        setRetorno(array)
}

useEffect(() => {
    Caso1()
},[email])

    return (

        <LinearGradient style={styles.container}
        colors={['#49009C', '#0D0D0D']}>
        <SafeAreaView>
            
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Casos Clínicos</Text>
            </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={55}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                        />
                </TouchableOpacity>
                
            </View>

        <ScrollView style={{flex: 1}}>
            <Text style={{color: "#FFF", fontSize: 45, textAlign: "center", marginTop: 80, padding: 20}}>Haga clic en el botón de abajo para enviar su caso clínico</Text>

                <View>
                    <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center',  marginTop: 120,}}>

                            <TouchableOpacity style={styles.Caso}  onPress={() => navigation.navigate("HomeCasoClinico")}>
                                <Text style={styles.MenuModulos}>🦷 Formula</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("HomeIntercept")}>
                                <Text style={styles.MenuModulos}>🦷 Intercept</Text>
                            </TouchableOpacity>

                            {retorno.length === 0 ?

                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("CASO1COE")}>
                                <Text style={styles.MenuModulos}>🦷 COE</Text>
                            </TouchableOpacity>

                            :

                            <TouchableOpacity style={styles.Caso}  onPress={() => navigation.navigate("ConsultaCaso")}>
                                <Text style={styles.MenuModulos}>🦷 COE</Text>
                            </TouchableOpacity>
                        }

                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("HomeDAO")}>
                                <Text style={styles.MenuModulos}>🦷 DAO</Text>
                            </TouchableOpacity>
                        
                    </View>

                </View>
            </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    )
}