import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    titulo: {
        textAlign: 'center',
        fontSize: 22,
        marginTop: 30,
        marginBottom: 30,
        marginRight: 10,
        fontWeight: 'bold',
        color: "#FFF"
    },

    header: {
        height: 115,
        backgroundColor: 'tranparent',
        marginTop: 10
    },

    Title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 20,
    },

    titleMenu: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
        marginBottom: 25,
        marginLeft: 25,
    },

    buttomMenu: {
        alignItems: 'left',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 10,
        marginLeft: 20,
    },

    descricao: {
        textAlign: 'center',
        fontSize: 17,
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 30,
        color: "#FFF"
    },

    buttomFoto: {
        width: 200,
        height: 55,
        justifyContent: 'center',
        backgroundColor: '#F3C53C',
        borderRadius: 20,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginBottom: 20,
    },

    buttomEnviar: {
        width: 150,
        height: 50,
        justifyContent: 'center',
        borderColor: '#F3C53C',
        borderStyle: 'solid',
        borderRadius: 20,
        alingItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop:30,
        marginBottom: 80,
        backgroundColor: "#fff",
        borderWidth: 1,
    },

    buttomEnviar2: {
        width: 150,
        height: 50,
        justifyContent: 'center',
        borderColor: '#F3C53C',
        borderStyle: 'solid',
        borderRadius: 20,
        alingItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop:30,
        marginBottom: 80,
        backgroundColor: "#fff",
        borderWidth: 1,
        opacity: 0.5
    },

    buttomDuvida: {
        position: 'absolute',
        marginTop: 15,
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: '#F3C53C',
        marginLeft: 340,
    },

    icon: {
        color: "#fff",
        fontSize: 20,
        textAlign: 'center',
        padding: 15,
        fontWeight: 'bold'
    },

    textCounter: {
        color: "#FFF",
        fontSize: 17,
        textAlign: 'left',
        fontWeight: 'bold',
        marginTop: 40,
        marginLeft: 10
    },

    img: {
        marginTop: 15,
        width: 70,
        height: 70,
        marginLeft: 10,
        borderRadius: 12,
    },

    textImg: {
        color: "#FFF",
        fontSize: 13,
        textAlign: 'left',
        marginTop: 7,
        marginLeft: 8
    },

    buttomAddFoto: {
        width: 70,
        height: 70,
        backgroundColor: '#fff',
        justifyContent: 'center',
        borderColor: '#F3C53C',
        borderStyle: 'dashed',
        borderWidth: 2,

        marginTop: 15,
        borderRadius: 12,

    },

    iconAddFoto: {
        color: "#FFF",
        fontSize: 20,
        textAlign: 'center',
        padding: 15,
    },

    container: {
        flex: 1,
    },

    descricao2: {
        textAlign: 'center',
        fontSize: 15,
        marginTop: 10,
        marginBottom: 50,
        marginLeft: 20,
        marginRight: 20,
        color: "#FFF"
    },

    descricao3: {
        textAlign: 'center',
        fontSize: 20,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#FFF",
        fontWeight: "bold"
    },

    descricao4: {
        textAlign: 'center',
        fontSize: 20,
        marginTop: 20,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        color: "#FFF",
        fontWeight: "bold"

    },

    Link: {
        textAlign: 'center',
        fontSize: 15,
        marginTop: 20,
        marginBottom: 10,
        color: "#FFF",
        borderBottomWidth: 1,
        borderColor: "#FFF",
    },

    textBotao: {
        color: "#000",
        textAlign: 'center',
        marginLeft: 5,
    },

    textbotaoEnviar:{
        color: "#000000",
        textAlign: 'center',
        fontSize: 15,
        fontWeight: "bold"
    },

    divUpload: {
        alignContent: "center", 
        alignItems: "center", 
        justifyContent: "center"
    },

    nameImg: {
        color: "#FFF", 
        fontSize: 15, 
        textAlign: "center", 
        marginLeft: 20, 
        marginRight: 20, 
        marginTop: -80
    },

    botaoEliminar: {
        backgroundColor: "#E60130", 
        width: 165, 
        height: 30,  
        borderRadius: 50, 
        marginTop: 20, 
        marginBottom: 20,
    },

    textEliminar: {
        color: "#FFF", 
        fontSize: 15, 
        textAlign: "center", 
        marginTop: 5
    }
});

export default styles