import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  Title: {
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: 25,
  },

  container: {
    flex: 1,
    zoom: 0.5,
  },

  menu: {
    alingnItens: "center",
    height: 975,
    width: 300,
    borderTopRightRadius: 70,
    borderBottomRightRadius: 70,
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftColor: "#000",
  },

  header: {
    height: 115,
    backgroundColor: "tranparent",
    marginTop: 10
  },

  titleMenu: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 10,
    marginBottom: 25,
    marginLeft: 25,
  },

  buttomMenu: {
    alignItems: "left",
    alignContent: "center",
    position: "absolute",
    marginTop: 10,
    marginLeft: 40,
  },

  MenuModulos: {
    fontSize: 30,
    textAlign: "center",
    color: "#000",
    fontWeight: "bold",
  },

  MenuModulosReprovado: {
    fontSize: 30,
    textAlign: "center",
    color: "#FFF",
    fontWeight: "bold",
  },

  MenuDescription: {
    fontSize: 20,
    textAlign: "center",
    color: "#fff",
  },

  divImg: {
    alingnItens: "center",
    alignContent: "center",
    alignSelf: "center",
    marginTop: 30
  },

  img: {
    width: 360,
    height: 155,
  },

  Caso: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    borderRadius: 20,
    width: 370,
    height: 110,
    marginTop: 40,
  },

  CasoAnalise: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#DC6201",
    borderRadius: 20,
    width: 370,
    height: 110,
    marginTop: 40,
  },

  CasoReprovado: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#DB0035",
    borderRadius: 20,
    width: 370,
    height: 110,
    marginTop: 40,
  },

  textAp: {
    color: "#FFF",
    fontSize: 35,
    textAlign: "justify",
    marginTop: 80,
    width: "1260px",
  },

  textAp2: {
    color: "#FFF",
    fontSize: 35,
    textAlign: "justify",
    marginTop: 80,
    width: "680px",
  },

  alinhadorMenu: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginTop: 120,
    marginBottom: 120,
  },

  alinhadorMenu2: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginTop: 40,
    marginBottom: 120,
  },
});

export default styles;
