import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    Title: {
        fontSize: 40,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 23,
    },

    container: {
        flex: 1,
        zoom: 0.50,
    },

    menu: {
        alingnItens: 'center',
        height: 975,
        width: 300,
        borderTopRightRadius: 70,
        borderBottomRightRadius: 70,
        borderColor: '#000',
        borderStyle: 'solid',
        borderWidth: 2,
        borderLeftColor: '#000'
    },

    header: {
        height: 115,
        backgroundColor: 'tranparent',
        marginTop: 10
    },

    titleMenu: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
        marginBottom: 25,
        marginLeft: 25,
    },

    buttomMenu: {
        alignItems: 'left',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 10,
        marginLeft: 40,
    },

    MenuModulos: {
        fontSize: 25,
        textAlign: 'center',
        color: '#000',
        marginTop: 27,
        fontWeight: 'bold',
    },

    MenuDescription: {
        fontSize: 20,
        textAlign: 'center',
        color: '#fff',
    },

    divImg: {
        alingnItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: 70
    },

    img: {
        width: 360,
        height: 110,
    },

    Caso: {
        backgroundColor: "#FFF", 
        borderRadius: 20, 
        width: 350, 
        height: 90,
        margin: 30, 
    }

});

export default styles