import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, SafeAreaView, ScrollView, TextInput, ActivityIndicator, Linking} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import {collection, query, where, getDocs, getFirestore, addDoc, Timestamp, deleteDoc, doc,} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import styles from "./style";
import * as DocumentPicker from "expo-document-picker";

export default function Caso2({ navigation }) {
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");

  const [image4, setImage4] = useState("");

  const [referenciadoc, setReferenciadoc] = useState("");
  const [referencia, setReferencia] = useState("");
  const [referenciaPDF, setReferenciaPDF] = useState("");

  const [permicao, setPermicao] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);

  const [uploading4, setUploading4] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;
  const [email, setEmail] = useState("");
  const [consulta, setConsulta] = useState(null);
  const [grupo, setGrupo] = useState("");
  const [count, setCount] = useState(0);

  const [retorno, setRetorno] = useState([]);
  const [nomeProf, setNomeProf] = useState("");
  const [st, setSt] = useState("Esperando validación de soporte");
  const [id, setID] = useState(null);
  const [data, setData] = useState("");
  const [validata, setValidata] = useState("");
  const [emailProf, setEmailProf] = useState("");

  const [diplo, setDiplo] = useState("");
  const [em, setEm] = useState("");

  const [dataAtual, setDataAtual] = useState("");

  const [consulta2, setConsulta2] = useState([]);

  const [nomePaciente, setNomePaciente] = useState("");

  const [larg, setLarg] = useState(null);

  setInterval(() => {
      var largura = window.screen.width;
      setLarg(largura);
    }, 100);

  const Tick = () => {
    var data = new Date();

    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    setDataAtual(dia + "/" + mes + "/" + ano);
  };

  async function Caso() {
    const db = getFirestore();
    const citiesRef = collection(db, "ConsultaErro");
    const q = query(citiesRef, where("numeroCaso", "==", "2"), where("email", "==", email), where("ifoUsuario.diplo", "not-in", ["COE", "Intecept", "Formula"]));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setID(doc.id);
      setNomeProf(doc.data().Prof);
      setData(doc.data().Envio);
      setValidata(doc.data().validata);
      setEmailProf(doc.data().emailProf);
      setReferencia(doc.data().Anamnesis);
      setReferenciadoc(doc.data().DocPaciente);
      setReferenciaPDF(doc.data().CefalometriaPaciente);

      setImage("Guía de Anamnesis");
      setImage2("Documentación para envío de caso clínico");
      setImage3("Webceph");

      const Obj = {
        id: doc.id,
        email: doc.data().email,
        numeroCaso: doc.data().numeroCaso,
        Prof: doc.data().Prof,
      };

      array.push(Obj);
    });
    setRetorno(array);
  }

  console.log(retorno);

  useEffect(() => {
    Caso();
  }, [email]);

  useEffect(() => {
    Verificação();
  }, [retorno]);

  async function Verificação() {
    setSt("Esperando validación de soporte para dao");
}

  const UploadResumos = async () => {
    try {
      const db = getFirestore();

      const docRef = await addDoc(collection(db, "Resumos"), {
        to: [email],
        message: {
          subject:"DAO - Recibimos su 2º caso clínico",
          html:
            "<p>Hola Doc. " +
            consulta.nome +
            ', ¡saludos! </p><p>Recibimos su caso clínico. La documentación enviada será analizada por el equipo de soporte y pronto nos pondremos en contacto para dar la devolutiva planificación del caso clínico.</p><p>Equipo de Soporte Profª Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
        },

        email: email,
        ifoUsuario: consulta,
        Anamnesis: referencia,
        DocPaciente: referenciadoc,
        CefalometriaPaciente: referenciaPDF,
        Envio: dataAtual,
        data: Timestamp.fromDate(new Date()),
        numeroCaso: "2",
        status: st,
        validata: validata,
        initData: "",
        Prof: nomeProf,
        Planej: "",
        face: "",
        tipo: "CASODAO",
        Arquivo: "",
        Motivo: "",
        nomePaciente: nomePaciente,
        CasoDAO: "Não Finalizado"
      });

      if (retorno.length !== 1) {
        const docRef3 = await addDoc(collection(db, "Termo"), {
          email: email,
          infoUsuario: consulta,
          Termo: permicao,
        });
      }

      if (retorno.length === 1) {
        const docRef2 = await addDoc(collection(db, "Resumos"), {
          to: [emailProf],
          message: {
            subject: "¡Caso clínico Reenviado!",
            html: '<p>Hola Doc.</p><p>Uno de los casos clínicos que usted reprobó fue reenviado, por favor mirar en la solapa "Casos DAO"</p><p>Equipo de Soporte Profª Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
          },
          data: Timestamp.fromDate(new Date()),
          Prof: nomeProf,
        });
        await deleteDoc(doc(db, "ConsultaErro", id));
      }

      if (retorno.length === 1 && nomeProf === "") {
        await deleteDoc(doc(db, "ConsultaErro", id));
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    alert(
      "¡Formulario enviado con éxito!",
      "Se envió un mensaje de confirmación a tu correo electrónico"
    );

    navigation.navigate("Home");
  };

  useEffect(() => {
    (async () => {
      if (user !== null) {
        user.providerData.forEach((profile) => {
          setEmail(profile.email);
        });
      }
    })();
  }, []);

  useEffect(() => {
    getInfo();
    Tick();
  }, [referenciadoc]);

  const getInfo = async () => {
    for (var i = 0; i <= 2; i++) {
      const db = getFirestore();
      const citiesRef = collection(db, "Usuarios");
      const q = query(citiesRef, where("email", "==", email));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setGrupo(doc.data().grupo);
        setDiplo(doc.data().diplo);

        const obj = {
          email: doc.data().email,
          grupo: "",
          nome: doc.data().nome,
          diplo: "DAO",
        };

        setConsulta(obj);
      });
    }
  };

  const alerta = () => {
    alert("Usted ya envió un caso clínico de un paciente con ese nombre, por favor verifique si está enviando el caso clínico correcto o si escribió el nombre correcto del paciente.")
  }

  const getPac = async () => {
    for (var i = 0; i <= 2; i++) {
      const db = getFirestore();
      const citiesRef = collection(db, "Resumos");
      const q = query(citiesRef, where("nomePaciente", "==", nomePaciente));

      let array = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

        const obj = {
         id:  doc.id,
         email: doc.data().email,
         numeroCaso: doc.data().numeroCaso,
        };

        array.push(obj)

        
      });

      setConsulta2(array);
    }
  };

  useEffect(() => {
    getPac();
   }, [nomePaciente]);
 
  useEffect(() => {
    setUploading(false);
    Verificacao();
  }, [referenciadoc]);

  useEffect(() => {
    setUploading2(false);
  }, [referencia]);

  useEffect(() => {
    setUploading3(false);
  }, [referenciaPDF]);

  useEffect(() => {
    setUploading4(false);
  }, [permicao]);

  const Verificacao = () => {
    if (diplo === "Intercept") {
      setEm("G10");
    } else {
      setEm("FC4");
    }
  };

  const pickImage = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: false,
        multiple: false,
      });
      console.log(result);

      var lastThree = result.name.substr(result.name.length - 3);

      setImage(result.name);
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", result.uri, true);
        xhr.send(null);
      });

      setUploading2(true);
      var url = Math.floor(Math.random() * 655366545782);

      const storage = getStorage();

      const fileImageRef = ref(
        storage,
        "gs://teste-5e945.appspot.com/Resumos/" + url + ".pdf"
      );
      const upload = await uploadBytes(fileImageRef, blob).then(() => {
        console.log("deu bom");
      });

      let UrlDownload = await getDownloadURL(fileImageRef);
      setReferencia(UrlDownload);
      blob.close();
    } catch (error) {}
  };

  const pickImage2 = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: false,
        multiple: false,
      });
      console.log(result);

      var lastThree = result.name.substr(result.name.length - 3);

      setImage2(result.name);
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", result.uri, true);
        xhr.send(null);
      });

      setUploading(true);
      var url = Math.floor(Math.random() * 655366545782);

      const storage = getStorage();

      const fileImageRef = ref(
        storage,
        "gs://teste-5e945.appspot.com/Resumos/" + url + ".pptx"
      );
      const upload = await uploadBytes(fileImageRef, blob);

      let UrlDownload = await getDownloadURL(fileImageRef);
      setReferenciadoc(UrlDownload);
      blob.close();
    } catch (error) {}
  };

  const pickImage3 = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: false,
        multiple: false,
      });
      console.log(result);

      var lastThree = result.name.substr(result.name.length - 3);

      setImage3(result.name);
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", result.uri, true);
        xhr.send(null);
      });

      setUploading3(true);
      var url = Math.floor(Math.random() * 655366545782);

      const storage = getStorage();

      const fileImageRef = ref(
        storage,
        "gs://teste-5e945.appspot.com/Resumos/" + url + ".pdf"
      );
      const upload = await uploadBytes(fileImageRef, blob);

      let UrlDownload = await getDownloadURL(fileImageRef);
      setReferenciaPDF(UrlDownload);
      blob.close();
    } catch (error) {}
  };

  const pickImage4 = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: false,
        multiple: false,
      });
      console.log(result);

      var lastThree = result.name.substr(result.name.length - 3);

      setImage4(result.name);
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", result.uri, true);
        xhr.send(null);
      });

      setUploading4(true);
      var url = Math.floor(Math.random() * 655366545782);

      const storage = getStorage();

      const fileImageRef = ref(
        storage,
        "gs://teste-5e945.appspot.com/Resumos/" + url + ".pdf"
      );
      const upload = await uploadBytes(fileImageRef, blob);

      let UrlDownload = await getDownloadURL(fileImageRef);
      setPermicao(UrlDownload);
      setCount(3);
      blob.close();
    } catch (error) {}
  };

  return (
    <LinearGradient style={styles.container} colors={["#262626", "#0D0D0D"]}>
      <SafeAreaView>
        <View style={styles.header}>
          <View>
            <Text style={styles.Title}>Entrega 2.º Caso Clínico</Text>
          </View>

          <TouchableOpacity
            style={styles.buttomMenu}
            onPress={() => navigation.goBack()}
          >
            <MaterialCommunityIcons
              name="reply"
              size={40}
              color="#fff"
              style={{ justifyContent: "center", alignContent: "center" }}
            />
          </TouchableOpacity>
        </View>

        <ScrollView style={{ flex: 1 }}>
          {retorno.length === 1 && (
            <>
              <Text style={styles.descricao3}>
                Adjunta la Guía de Anamnesis
              </Text>

              {uploading2 === false && image === "" ? (
                <View />
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading2}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading2 === false && image !== "" && referencia != "" && (
                <View style={styles.divUpload}>
                  <Text style={styles.nameImg}>📄 {image}</Text>

                  <TouchableOpacity
                    onPress={pickImage}
                    style={styles.botaoEliminar}
                  >
                    <Text style={styles.textEliminar}>Enviar otro archivo</Text>
                  </TouchableOpacity>
                </View>
              )}

              <Text style={styles.descricao4}>
                Adjunta la documentación completa del paciente (PowerPoint)
              </Text>

              {uploading === false && image2 === "" ? (
                <View />
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading === false &&
                image2 !== "" &&
                referenciadoc != "" && (
                  <View style={styles.divUpload}>
                    <Text style={styles.nameImg}>📄 {image2}</Text>

                    <TouchableOpacity
                      onPress={pickImage2}
                      style={styles.botaoEliminar}
                    >
                      <Text style={styles.textEliminar}>
                        Enviar otro archivo
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              <Text style={styles.descricao4}>
                Adjunta la cefalometría del paciente (PDF - WebCeph)
              </Text>

              {uploading3 === false && image3 === "" ? (
                <View />
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading3}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading3 === false &&
                image3 !== "" &&
                referenciaPDF != "" && (
                  <View style={styles.divUpload}>
                    <Text style={styles.nameImg}>📄 {image3}</Text>

                    <TouchableOpacity
                      onPress={pickImage3}
                      style={styles.botaoEliminar}
                    >
                      <Text style={styles.textEliminar}>
                        Enviar otro archivo
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              <Text style={styles.descricao}>
                Cualquier duda, puedes enviar un mensaje:
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://api.whatsapp.com/send?phone=5514996207009&text=Hola,%20estoy%20en%20el%20Portal%20OSF%20-%20%20Env%C3%ADo%20de%20Casos%20Cl%C3%ADnicos%20y%20tengo%20una%20duda"
                    );
                  }}
                >
                  <Text style={styles.Link}> +55 14 99620-7009</Text>
                </TouchableOpacity>
              </Text>

              {referenciadoc != "" && (
                  <TouchableOpacity
                    style={styles.buttomEnviar}
                    onPress={UploadResumos}
                  >
                    <Text style={styles.textbotaoEnviar}>Enviar</Text>
                  </TouchableOpacity>
                )}
            </>
          )}

          {retorno.length === 0 && (
            <>

              <Text style={styles.descricao4}>
                Adjunta la documentación completa del paciente (PowerPoint)
              </Text>

              {uploading === false && image2 === "" ? (
                <TouchableOpacity
                  style={styles.buttomFoto}
                  onPress={pickImage2}
                >
                  <MaterialCommunityIcons
                    name="file-export"
                    size={24}
                    color="#000"
                  />

                  <Text style={styles.textBotao}> Seleccionar un archivo</Text>
                </TouchableOpacity>
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading === false &&
                image2 !== "" &&
                referenciadoc != "" && (
                  <View style={styles.divUpload}>
                    <Text style={styles.nameImg}>📄 {image2}</Text>

                    <TouchableOpacity
                      onPress={() => setImage2("")}
                      style={styles.botaoEliminar}
                    >
                      <Text style={styles.textEliminar}>Eliminar</Text>
                    </TouchableOpacity>
                  </View>
                )}


              <View style={{justifyContent: "center", alignItems: "center"}}>
                <TextInput 
                    style={{
                        alignItems: 'center', width: 320, margin: 20, padding: 10,
                        height: 60, borderWidth: 1, borderColor: "#fff", borderRadius: 10,
                        color: "#fff", fontSize: 17, fontWeight: "bold",
                    }}
                        placeholder="Nombre del paciente..."
                        placeholderTextColor="#fff"
                        type="text"
                        onChangeText={(text) => setNomePaciente(text)}
                        value={nomePaciente} 
                />
              </View>

              <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}> 
                  {larg <= 500 ?
                    <View style={{height:2, width: 350, margin: 30, borderTopStyle: "dashed", borderTopColor: "#FFF", borderWidth: 1,}}/>
                    :
                    <View style={{height:2, width: 980, margin: 30, borderTopStyle: "dashed", borderTopColor: "#FFF", borderWidth: 1,}}/>
                  }

                  <Text style={larg <= 500 ? styles.textAp2 : styles.textAp }>
                    Los ítems abajo, no son obligatorios, pero si los tiene,  hará que su caso sea planificado más rápidamente
                  </Text>
              </View>


              <Text style={styles.descricao3}>
                Adjunta la Guía de Anamnesis
              </Text>

              {uploading2 === false && image === "" ? (
                <TouchableOpacity style={styles.buttomFoto} onPress={pickImage}>
                  <MaterialCommunityIcons
                    name="file-export"
                    size={24}
                    color="#000"
                  />

                  <Text style={styles.textBotao}> Seleccionar un archivo</Text>
                </TouchableOpacity>
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading2}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading2 === false && image !== "" && referencia != "" && (
                <View style={styles.divUpload}>
                  <Text style={styles.nameImg}>📄 {image}</Text>

                  <TouchableOpacity
                    onPress={() => setImage("")}
                    style={styles.botaoEliminar}
                  >
                    <Text style={styles.textEliminar}>Eliminar</Text>
                  </TouchableOpacity>
                </View>
              )}

              <Text style={styles.descricao4}>
                Adjunta la cefalometría del paciente (PDF - WebCeph)
              </Text>

              {uploading3 === false && image3 === "" ? (
                <TouchableOpacity
                  style={styles.buttomFoto}
                  onPress={pickImage3}
                >
                  <MaterialCommunityIcons
                    name="file-export"
                    size={24}
                    color="#000"
                  />

                  <Text style={styles.textBotao}> Seleccionar un archivo</Text>
                </TouchableOpacity>
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading3}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading3 === false &&
                image3 !== "" &&
                referenciaPDF != "" && (
                  <View style={styles.divUpload}>
                    <Text style={styles.nameImg}>📄 {image3}</Text>

                    <TouchableOpacity
                      onPress={() => setImage3("")}
                      style={styles.botaoEliminar}
                    >
                      <Text style={styles.textEliminar}>Eliminar</Text>
                    </TouchableOpacity>
                  </View>
                )}

              <Text style={styles.descricao4}>
                Término de autorización del paciente (PDF)
              </Text>

              {uploading4 === false && image4 === "" ? (
                <TouchableOpacity
                  style={styles.buttomFoto}
                  onPress={pickImage4}
                >
                  <MaterialCommunityIcons
                    name="file-export"
                    size={24}
                    color="#000"
                  />

                  <Text style={styles.textBotao}> Seleccionar un archivo</Text>
                </TouchableOpacity>
              ) : (
                <ActivityIndicator
                  style={{ marginTop: 25 }}
                  animating={uploading4}
                  size={60}
                  color="#F3C53C"
                />
              )}

              {uploading4 === false && image4 !== "" && permicao != null && (
                <View style={styles.divUpload}>
                  <Text style={styles.nameImg}>📄 {image4}</Text>

                  <TouchableOpacity
                    onPress={() => setImage4("")}
                    style={styles.botaoEliminar}
                  >
                    <Text style={styles.textEliminar}>Eliminar</Text>
                  </TouchableOpacity>
                </View>
              )}
              
              <Text style={styles.descricao}>
                Cualquier duda, puedes enviar un mensaje:
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://api.whatsapp.com/send?phone=5514996207009&text=Hola,%20estoy%20en%20el%20Portal%20OSF%20-%20%20Env%C3%ADo%20de%20Casos%20Cl%C3%ADnicos%20y%20tengo%20una%20duda"
                    );
                  }}
                >
                  <Text style={styles.Link}> +55 14 99620-7009</Text>
                </TouchableOpacity>
              </Text>

              {nomePaciente == "" &&(
                <TouchableOpacity style={styles.buttomEnviar2}>
                  <Text style={styles.textbotaoEnviar}>Enviar</Text>
                </TouchableOpacity>
              )}

              {referenciadoc != "" &&
                nomePaciente != "" &&(
                  <TouchableOpacity
                    style={styles.buttomEnviar}
                    onPress={consulta2.length == 1 ? alerta : UploadResumos}
                  >
                    <Text style={styles.textbotaoEnviar}>Enviar</Text>
                  </TouchableOpacity>
                )}
            </>
          )}
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}
