import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, SafeAreaView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs, getFirestore, and} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';


export default function HomeIntercept({ navigation }) {

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail]= useState("");
   
    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);
    const [diplo, setDiplo] = useState(null)
    
    
    useFocusEffect(
        React.useCallback(() => {
            if (user !== null) {
                user.providerData.forEach((profile) => {
                    setEmail(profile.email)
                });
            } 
        }, [])
      );

    async function Caso1() {
                  
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("numeroCaso", "==", '1'), where("email", "==", email), where("ifoUsuario.diplo", "not-in", ['COE', 'DAO', 'Formula']))
        let array = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                status: doc.data().status,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente
            };
            array.push(Obj)
        });

        setRetorno(array)
}

async function Caso2() {
                  
    const db = getFirestore();
    const citiesRef = collection(db, "Resumos");
    const q = query(citiesRef, where("numeroCaso", "==", '2'), where("email", "==", email), where("ifoUsuario.diplo", "not-in", ['COE', 'DAO', 'Formula']));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        
        const Obj = {
            id: doc.id,
            email: doc.data().email,
            status: doc.data().status,
            Anamnesis: doc.data().Anamnesis,
            CefalometriaPaciente: doc.data().CefalometriaPaciente,
            DocPaciente: doc.data().DocPaciente
        };
        array.push(Obj)
    });

    setRetorno2(array)
}

async function Caso3() {
                  
    const db = getFirestore();
    const citiesRef = collection(db, "Resumos");
    const q = query(citiesRef, where("numeroCaso", "==", '3'), where("email", "==", email), where("ifoUsuario.diplo", "not-in", ['COE', 'DAO', 'Formula']));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        
        const Obj = {
            id: doc.id,
            email: doc.data().email,
            status: doc.data().status,
            Anamnesis: doc.data().Anamnesis,
            CefalometriaPaciente: doc.data().CefalometriaPaciente,
            DocPaciente: doc.data().DocPaciente
        };
        array.push(Obj)
    });

    setRetorno3(array)
}


const getInfo = async () => {

    for (var i = 0; i <= 2; i++) {
        const db = getFirestore();
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        setDiplo(doc.data().diplo);
      });
    }
}


 useEffect(() => {
    Caso1();
}, [email])

useEffect(() => {
    Caso2();
}, [email])

useEffect(() => {
    Caso3();
}, [email])


    return (

        <LinearGradient style={styles.container}
        colors={['#054A59', '#0D0D0D']}>
        <SafeAreaView>
            
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Casos Clínicos</Text>
            </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={55}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                        />
                </TouchableOpacity>
                
            </View>

            <View style={styles.divImg}>
                <Image 
                    style={styles.img} source={{
                    uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2Fintercept.svg?alt=media&token=7f19134c-f301-4e1c-a165-3652f8a262dc',
                }} />
            </View>

        <ScrollView style={{flex: 1}}>
            <Text style={{color: "#FFF", fontSize: 45, textAlign: "center", marginTop: 80, padding: 20}}>Haga clic en el botón de abajo para enviar su caso clínico</Text>

                <View>
                    <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center',  marginTop: 120,}}>

                        {retorno.length === 0 ?
                            <TouchableOpacity style={styles.Caso}  onPress={() => navigation.navigate("Caso1I")}>
                                <Text style={styles.MenuModulos}>🦷 1.º Caso clínico</Text>
                            </TouchableOpacity>

                            :

                            <TouchableOpacity style={styles.Caso}  onPress={() => navigation.navigate("ConsultaCaso1I")}>
                                <Text style={styles.MenuModulos}>🦷 1.º Caso clínico</Text>
                            </TouchableOpacity>
                        }

                        {retorno2.length === 0 ?
                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("Caso2I")}>
                                <Text style={styles.MenuModulos}>🦷 2.º Caso clínico</Text>
                            </TouchableOpacity>
                            :

                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("ConsultaCaso2I")}>
                                <Text style={styles.MenuModulos}>🦷 2.º Caso clínico</Text>
                            </TouchableOpacity>

                        }

                        {retorno3.length === 0 ?
                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("Caso3I")}>
                                <Text style={styles.MenuModulos}>🦷 3.º Caso clínico</Text>
                            </TouchableOpacity>
                            :
                            
                            <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("ConsultaCaso3I")}>
                                <Text style={styles.MenuModulos}>🦷 3.º Caso clínico</Text>
                            </TouchableOpacity>

                        }

                        <TouchableOpacity style={styles.Caso} onPress={() => navigation.navigate("HomeCasoExtra")}>
                            <Text style={styles.MenuModulos}>🦷 Caso Clínico Extra</Text>
                        </TouchableOpacity>


                    </View>

                </View>
            </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    )
}